
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CardSelect extends Vue {
  @Prop({ required: true, default: [] })
  protected data!: Array<any>;

  @Prop({ required: false, default: [] })
  protected disabledIndexes!: Array<number>;

  @VModel()
  protected model!: any;
}
