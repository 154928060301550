
import { Component, Prop, Vue } from "vue-property-decorator";
import { ITent } from "@/interfaces/models/ITent";

@Component({
  components: {},
})
export default class TentDropdownItem extends Vue {
  @Prop({ required: true })
  protected tent!: ITent;

  @Prop({ required: false, default: false })
  protected isDisabled!: boolean;
}
