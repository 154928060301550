var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getPlantDetailIsSaving,"mode":_vm.mode,"is-horizontal":false},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',{staticClass:"is-pulled-right"},[_c('b-button',{attrs:{"size":"is-small"},on:{"click":_vm.clearForm}},[_vm._v(_vm._s(_vm.$t("general.reset_form"))+" ")])],1),_c('div',{staticClass:"is-clearfix"}),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"is-fullwidth has-text-right"},[_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.datetime_of_action')),"type":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.datetime_of_action
                      ? 'is-danger'
                      : '',"message":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.datetime_of_action
                      ? _vm.getPlantDetailValidationErrors.datetime_of_action
                      : ''}},[_c('b-datetimepicker',{attrs:{"placeholder":"Select date and time","first-day-of-week":1},model:{value:(_vm.form.datetime_of_action),callback:function ($$v) {_vm.$set(_vm.form, "datetime_of_action", $$v)},expression:"form.datetime_of_action"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.event')),"type":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.event
                      ? 'is-danger'
                      : '',"message":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.event
                      ? _vm.getPlantDetailValidationErrors.event
                      : '',"position":"is-right"}},[_c('b-select',{attrs:{"placeholder":"Bitte ein Ereignis auswählen"},model:{value:(_vm.form.event),callback:function ($$v) {_vm.$set(_vm.form, "event", $$v)},expression:"form.event"}},_vm._l((_vm.plantDetailEvents),function(event){return _c('option',{key:'plant-detail-event-' + event.value,domProps:{"value":event.value}},[_vm._v(" "+_vm._s(_vm.$t(event.title))+" ")])}),0)],1)],1),_c('div',{staticClass:"is-clearfix"}),_c('b-button',{staticClass:"is-pulled-left",attrs:{"disabled":!_vm.isFillFormButtonVisible,"size":"is-small"},on:{"click":_vm.setRecentPlantDetailEventData}},[_vm._v(_vm._s(_vm.$t("general.form.fill"))+" ")]),_c('div',{staticClass:"is-clearfix"})],1),_c('hr'),_c('div',{staticClass:"mb-3"},[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.event_info')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.event_info
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.event_info
                    ? _vm.getPlantDetailValidationErrors.event_info
                    : ''}},[_c('b-input',{attrs:{"type":"text","placeholder":"Event Info"},model:{value:(_vm.form.event_info),callback:function ($$v) {_vm.$set(_vm.form, "event_info", $$v)},expression:"form.event_info"}})],1)],1),(_vm.form.event === '')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("modules.plant_details.events.dropdown_please_choose"))+" ")]):(_vm.form.event === 'watering')?_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.watering_milliliters')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.watering_milliliters
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.watering_milliliters
                    ? _vm.getPlantDetailValidationErrors.watering_milliliters
                    : ''}},[_c('b-input',{attrs:{"type":"number","placeholder":"Watering in liters"},model:{value:(_vm.form.watering_milliliters),callback:function ($$v) {_vm.$set(_vm.form, "watering_milliliters", $$v)},expression:"form.watering_milliliters"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.with_fertilizer')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.with_fertilizer
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.with_fertilizer
                    ? _vm.getPlantDetailValidationErrors.with_fertilizer
                    : ''}},[_c('b-switch',{attrs:{"false-value":0,"true-value":1},model:{value:(_vm.form.with_fertilizer),callback:function ($$v) {_vm.$set(_vm.form, "with_fertilizer", $$v)},expression:"form.with_fertilizer"}},[_vm._v(" "+_vm._s(_vm.form.with_fertilizer ? _vm.$t("general.yes") : _vm.$t("general.no"))+" ")])],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.used_fertilizer')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.used_fertilizer
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.used_fertilizer
                    ? _vm.getPlantDetailValidationErrors.used_fertilizer
                    : ''}},[_c('b-input',{attrs:{"type":"text","placeholder":"Used fertilizer","disabled":!_vm.form.with_fertilizer},model:{value:(_vm.form.used_fertilizer),callback:function ($$v) {_vm.$set(_vm.form, "used_fertilizer", $$v)},expression:"form.used_fertilizer"}})],1)],1):(_vm.form.event === 'repotting')?_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.pot_shape'))}},[_c('b-select',{model:{value:(_vm.potShape),callback:function ($$v) {_vm.potShape=$$v},expression:"potShape"}},[_c('option',{attrs:{"value":"round"}},[_vm._v(" "+_vm._s(_vm.$t("modules.plant_details.pot_shapes.round"))+" ")]),_c('option',{attrs:{"value":"square"}},[_vm._v(" "+_vm._s(_vm.$t("modules.plant_details.pot_shapes.square"))+" ")])])],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal}},[(_vm.potShape === 'round')?_c('b-field',{attrs:{"label":String(_vm.$t('modules.plant_details.diameter'))}},[_c('b-input',{attrs:{"type":"number"},on:{"input":_vm.onPotSizeDataChanged},model:{value:(_vm.potDiameter),callback:function ($$v) {_vm.potDiameter=$$v},expression:"potDiameter"}})],1):_vm._e(),(_vm.potShape === 'square')?_c('b-field',{attrs:{"label":String(_vm.$t('modules.plant_details.width'))}},[_c('b-input',{attrs:{"type":"number"},on:{"input":_vm.onPotSizeDataChanged},model:{value:(_vm.potWidth),callback:function ($$v) {_vm.potWidth=$$v},expression:"potWidth"}})],1):_vm._e(),(_vm.potShape === 'square')?_c('b-field',{attrs:{"label":String(_vm.$t('modules.plant_details.length'))}},[_c('b-input',{attrs:{"type":"number"},on:{"input":_vm.onPotSizeDataChanged},model:{value:(_vm.potLength),callback:function ($$v) {_vm.potLength=$$v},expression:"potLength"}})],1):_vm._e(),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.soil_type')),"type":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.soil_type
                      ? 'is-danger'
                      : '',"message":_vm.getPlantDetailValidationErrors &&
                    _vm.getPlantDetailValidationErrors.soil_type
                      ? _vm.getPlantDetailValidationErrors.soil_type
                      : ''}},[_c('b-input',{attrs:{"type":"text","placeholder":"Soil type"},model:{value:(_vm.form.soil_type),callback:function ($$v) {_vm.$set(_vm.form, "soil_type", $$v)},expression:"form.soil_type"}})],1),_c('b-field',{attrs:{"label":String(_vm.$t('modules.plant_details.height'))}},[_c('b-input',{staticClass:"ml-1",attrs:{"type":"number"},on:{"input":_vm.onPotSizeDataChanged},model:{value:(_vm.potHeight),callback:function ($$v) {_vm.potHeight=$$v},expression:"potHeight"}})],1)],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.pot_size')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_size
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_size
                    ? _vm.getPlantDetailValidationErrors.pot_size
                    : ''}},[_c('b-input',{attrs:{"type":"text","placeholder":"BxTxH or DxH"},model:{value:(_vm.form.pot_size),callback:function ($$v) {_vm.$set(_vm.form, "pot_size", $$v)},expression:"form.pot_size"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.pot_capacity_liters')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_capacity_liters
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_capacity_liters
                    ? _vm.getPlantDetailValidationErrors.pot_capacity_liters
                    : ''}},[_c('b-input',{attrs:{"type":"number","placeholder":"Pot capacity in liters"},model:{value:(_vm.form.pot_capacity_liters),callback:function ($$v) {_vm.$set(_vm.form, "pot_capacity_liters", $$v)},expression:"form.pot_capacity_liters"}})],1)],1):(_vm.form.event === 'update_growth_stage')?_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.growth_stage')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.growth_stage
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.growth_stage
                    ? _vm.getPlantDetailValidationErrors.growth_stage
                    : ''}},[_c('b-select',{attrs:{"placeholder":"Select growth stage"},model:{value:(_vm.form.growth_stage),callback:function ($$v) {_vm.$set(_vm.form, "growth_stage", $$v)},expression:"form.growth_stage"}},_vm._l((_vm.orderedPlantDetailGrowthStages),function(stage,stageIdx){return _c('option',{key:'plant-detail-growth-stage-' + stageIdx,domProps:{"value":stageIdx}},[_vm._v(" "+_vm._s(_vm.$t(stage.label))+" ")])}),0)],1)],1):(_vm.form.event === 'harvesting')?_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.growth_stage')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.growth_stage
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.growth_stage
                    ? _vm.getPlantDetailValidationErrors.growth_stage
                    : ''}},[_c('b-select',{attrs:{"placeholder":"Select growth stage"},model:{value:(_vm.form.growth_stage),callback:function ($$v) {_vm.$set(_vm.form, "growth_stage", $$v)},expression:"form.growth_stage"}},_vm._l((_vm.orderedPlantDetailGrowthStages),function(stage,stageIdx){return _c('option',{key:'plant-detail-growth-stage-' + stageIdx,domProps:{"value":stageIdx}},[_vm._v(" "+_vm._s(_vm.$t(stage.label))+" ")])}),0)],1),(_vm.form.growth_stage === 'harvest')?_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.harvested_wet_value_g')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.harvested_wet_value_g
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.harvested_wet_value_g
                    ? _vm.getPlantDetailValidationErrors.harvested_wet_value_g
                    : ''}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.form.harvested_wet_value_g),callback:function ($$v) {_vm.$set(_vm.form, "harvested_wet_value_g", $$v)},expression:"form.harvested_wet_value_g"}})],1):(_vm.form.growth_stage === 'fermenting')?_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.harvested_dry_netto_g')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.harvested_dry_netto_g
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.harvested_dry_netto_g
                    ? _vm.getPlantDetailValidationErrors.harvested_dry_netto_g
                    : ''}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.form.harvested_dry_netto_g),callback:function ($$v) {_vm.$set(_vm.form, "harvested_dry_netto_g", $$v)},expression:"form.harvested_dry_netto_g"}})],1):_vm._e()],1):(_vm.form.event === 'move_to_tent')?_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
                    _vm.$t('modules.plant_details.move_to_tent_select_label')
                  ),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.tent_id
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.tent_id
                    ? _vm.getPlantDetailValidationErrors.tent_id
                    : ''}},[_c('CardSelect',{attrs:{"data":_vm.tents,"disabled-indexes":_vm.tents
                      .map((tent, index) =>
                        tent.slot_count <= tent.plants_count ? index : -1
                      )
                      .filter((index) => index !== -1)},scopedSlots:_vm._u([{key:"default",fn:function({ active, item }){return [_c('div',{staticClass:"card p-3",class:{ 'is-active': active },staticStyle:{"border-radius":"8px","border-width":"1px","border-style":"solid"},style:({
                        'border-color': active ? '#000' : '#ddd',
                      })},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_c('p',{staticClass:"has-text-weight-bold mb-2"},[_vm._v(" "+_vm._s(item.tent)+" ")])])]),_c('table',{staticClass:"table is-fullwidth"},[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$tc( "modules.tent.free_slots", item.slot_count - item.plants_count, { free_slot_count: item.slot_count - item.plants_count, } ))+" ")])]),_c('tr',[_c('td',[_c('span',[_c('i',{staticClass:"fa-solid fa-sun"})])]),_c('td',[_vm._v(_vm._s(item.light_on_time))])]),_c('tr',[_c('td',[_c('span',[_c('i',{staticClass:"fa-solid fa-moon"})])]),_c('td',[_vm._v(_vm._s(item.light_off_time))])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"heatmap-wrapper",staticStyle:{"display":"grid","gap":"2px","padding":"5px","border":"1px solid #ddd","border-radius":"5px","background-color":"#f9f9f9","vertical-align":"bottom","align-self":"flex-end"},style:({
                                'grid-template-columns':
                                  'repeat(' + item.slot_count + ', 1fr)',
                              })},_vm._l((item.slot_count),function(n){return _c('div',{key:n,style:({
                                  height: '10px',
                                  width: '100%',
                                  backgroundColor:
                                    n <= item.plants_count
                                      ? '#48c774'
                                      : '#e0e0e0',
                                })})}),0)])])])])]}}],null,true),model:{value:(_vm.selectedTent),callback:function ($$v) {_vm.selectedTent=$$v},expression:"selectedTent"}})],1)],1):_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.pot_weight_grams')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_net_weight_grams
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.pot_net_weight_grams
                    ? _vm.getPlantDetailValidationErrors.pot_net_weight_grams
                    : ''}},[_c('b-input',{attrs:{"type":"number","placeholder":"Pot net weight in grams"},model:{value:(_vm.form.pot_net_weight_grams),callback:function ($$v) {_vm.$set(_vm.form, "pot_net_weight_grams", $$v)},expression:"form.pot_net_weight_grams"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.plant_details.plant_height_cm')),"type":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.plant_height_cm
                    ? 'is-danger'
                    : '',"message":_vm.getPlantDetailValidationErrors &&
                  _vm.getPlantDetailValidationErrors.plant_height_cm
                    ? _vm.getPlantDetailValidationErrors.plant_height_cm
                    : ''}},[_c('b-input',{attrs:{"type":"number","placeholder":"Plant height in cm"},model:{value:(_vm.form.plant_height_cm),callback:function ($$v) {_vm.$set(_vm.form, "plant_height_cm", $$v)},expression:"form.plant_height_cm"}})],1)],1),(_vm.form.growth_stage && _vm.form.event === 'update_growth_stage')?_c('b-notification',{staticClass:"mt-3",attrs:{"type":"is-info is-light","closable":false}},[_c('span',[_vm._v(_vm._s(_vm.$t( "modules.plant_details.growth_stages.info." + _vm.form.growth_stage )))])]):_vm._e()],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }